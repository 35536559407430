.circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 6px;
    background: #fff;
    border: 3px solid #000;
    font-weight: bold;
    color: #000;
    text-align: center;
    font: 32px Arial, sans-serif;
}