.mySelection {
    max-height: 800px;
    max-width: 600px;
    min-height: 400px;
    min-width: 250px;
    border-radius: 10px;
    border: solid 1px black;
    padding: 10px;
    font-weight: bold;
    color: white;
    text-align: center;
    background-color: #D48271;
    font-size: 20px;
}

.flex {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
}