.card {
    margin: 1rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
}
  
.halfPage {
  width: 45%;
}

.modal {
  position: fixed;
  top: 5%;
  left: 10%;
  width: 70%;
  z-index: 100;
  overflow: scroll;
  background-color: #fff;
  max-height: 80%;
}

.green {
  background-color: green;
}


.warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.flex {
  display: flex;
}

.regular {
  margin: 20px;
  width: 600px;
  height: 600px;
}

.large {
  margin: 20px;
  width: 1200px;
  height: 600px;
}