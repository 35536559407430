.section {
    width: 100%;
    margin-left: 2%;
}

.choices {
    margin-right: 10%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70vh;
}

.btnGroup button:not(:last-child) {
    border-bottom: none;
}

.btnGroup button:hover {
    background-color: #067be9;
}