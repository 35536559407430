.gtagItem {
    border-radius: 10px;
    width: 450px;
    min-height: 150px;
    background-color: #87C298;
    padding: 10px;
    margin: 10px;
    border: solid 2px black;
    cursor: pointer;
    color: white;
}

.gtagItem:active {
    height: 110%;
    width: 80%;
}
