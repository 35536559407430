.warningDiv {
    border: 2px solid red;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
}

.message {
    padding: 10px;
    margin: 1rem;
    font-size: 22px;
}

