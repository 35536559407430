
.modal {
    position: absolute;
    top: 2vh;
    left: 58%;
    width: 90%;
    z-index: 100;
    overflow: hidden;
    background-color: #fff;
  }

.header {
  background: #4f005f;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 0.5rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.large {
  width: 100%;
}