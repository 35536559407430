.App {
    text-align: center;
}

html, body {
    height: 100%;
}

html, body {
  height: 100%;
}

body {
    margin: 0;
    font-family: 'Helvetica Neue', 'Arial', 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.isInvalid {
    background-color: red;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

h1, h2, h3, h4, h5, h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

h1, h2, h3 {
  margin: 0.6em 0;
}

h4 {
  margin: 0.5em 0;
}

h5 {
  margin: 0.4em 0;
}

h6 {
  margin: 0.2em 0;
}
