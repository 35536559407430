.secondary {
    height: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: #845EF7;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 8px;
    border: 1px solid #845EF7;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 12px;
    gap: 4px;
    width: 305px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    font-weight: bold; 
    margin: 10px;
}

.small {
    min-width : 100px;
}

.large {
    min-width : 500px;
}