.partnerItem {
    border-radius: 10px;
    min-width: 250px;
    height: 120px;
    background-color: #98C3E1;
    padding: 10px;
    margin: 10px;
    border: solid 2px blue;
    cursor: pointer;
}

.partnerItem:active {
    height: 110%;
    width: 80%;
}

.name {
    color: white;
    font-size: 20px;
}

