.googleTagsList {
    max-height: 800px;
    min-height: 700px;
    max-width: 600px;
    min-width: 250px;
    overflow: scroll;
    border-radius: 10px;
    border: solid 1px black;
    padding: 10px;
}

.flex {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
}