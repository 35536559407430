.primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 12px;
    gap: 4px;
    min-width: 305px;
    height: 40px;
    background-color: #845EF7;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.16), 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
    transition: 250ms;
    border-radius: 4px;
    flex: none;
    flex-grow: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30%;
    color: #FFF;
    border-style: none;
    cursor: pointer;
    margin: 10px 0;
}

.primary:hover {
    background-color: #6b3aff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.16), 0px 1px 10px 0px rgba(0, 0, 0, 0.14);
}

.primary:disabled {
    cursor: default;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.12);
    box-shadow: none;
    color: rgba(0, 0, 0, 0.26);
}

.small {
    min-width : 100px;
}

.large {
    min-width : 500px;
}